import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import CasagrandHazen from "../../../components/developers/casagranddeveloper/ourproperties/CasagrandHazen";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const CasagrandHazenPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <CasagrandHazen />
    </>
  )
}

export default CasagrandHazenPage
